/* eslint-disable */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Check } from 'emotion-icons/material'
import { Layout } from '@leshen/gatsby-theme-contentful'
import header from '../../components/Header'
import footer from '../../components/Footer'
import ResponsiveGrid from '../../components/GridLayouts/ResponsiveGrid'
import engagement from '../../images/illustrations/engagement-blue.png'
import intelligence from '../../images/illustrations/bar-graph-blue.png'
import strategy from '../../images/illustrations/node-blue.png'
import ecommerce from '../../images/illustrations/ecommerce.png'

const Home = () => {
  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Social Media Management | Clearlink',
          description:
            'A strong social media presence has never been more vital to a brand’s success. Learn how our intelligence, strategy, and engagement offerings can benefit your brand.',
          robots: 'follow,index',
        },
        path: '/socialx/services',
        hideStickyCTA: false,
        spanish: false,
        sections: [],
      },
      site: {
        siteMetadata: {
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Social Media Management | Clearlink',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(),
    main: (
      <>
        <VariableContent
          // mainContent
          alignMainContent="center"
          backgroundColor="dark"
        >
          <Stack spacing="l">
            <Typography
              variant="h1"
              className="center-desktop"
              style={{ margin: '0 auto 32px' }}
            >
              SocialX: Intelligence
              <span className="blue-punctuation-large">.</span> Strategy
              <span className="blue-punctuation-large">.</span> Engagement
              <span className="blue-punctuation-large">.</span>
            </Typography>
            <div className="narrow center">
              <Typography
                style={{ margin: '0 auto 32px' }}
                className="center-desktop"
              >
                A strong social media presence has never been more vital to a
                brand’s success. By leveraging SocialX’s Intelligence, Strategy,
                and Engagement offerings, brands can build awareness, increase
                positive consumer sentiment, and become inclusive community
                leaders.
              </Typography>
            </div>
            <ResponsiveGrid
              numberOfColumns={3}
              numberOfMobileColumns={1}
              constraint="small"
            >
              <LinkButton
                to="#intelligence"
                color="white"
                outlined
                ghost
                data-sal="slide-up"
                data-sal-delay="600"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                Social Intelligence
              </LinkButton>
              <LinkButton
                to="#strategy"
                color="white"
                outlined
                ghost
                data-sal="slide-up"
                data-sal-delay="600"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                Social Strategy
              </LinkButton>
              <LinkButton
                to="#engagement"
                color="white"
                outlined
                ghost
                data-sal="slide-up"
                data-sal-delay="600"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                Social Engagement
              </LinkButton>
            </ResponsiveGrid>
          </Stack>
        </VariableContent>
        <SplitContent
          id="intelligence"
          image={
            <img
              src={intelligence}
              alt="intelligence illustration"
              placeholder="blurred"
            />
          }
          mobileImage={
            <img
              src={intelligence}
              alt="intelligence illustration"
              placeholder="blurred"
              width={250}
              height={250}
            />
          }
          mainContent={
            <div
              data-sal="slide-left"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Typography variant="h2">
                Intelligence<span className="blue-punctuation-large">.</span>
              </Typography>

              <Typography style={{ marginBottom: '26px' }}>
                We provide an understanding of the social conversation using
                real-time and historical research, reactive and proactive
                listening, and analysis of industry and brand-related topic
                mentions across social channels.
              </Typography>
              <Typography style={{ marginBottom: '26px' }}>
                We scour social networks, forums, reviews, blogs, and media
                sites for data to provide strategic recommendations that fuel
                your brand’s social media, content marketing, paid media,
                product, and go-to-market strategies. Simply put, we’ve got 👀on
                everything so you don’t have to.
              </Typography>
              <Typography style={{ marginBottom: '26px' }}>
                TL;DR: from product development to trend reporting and
                competitive analysis, SocialX is your go-to resource that
                ensures you meet your consumers where they are while giving them
                what they want, how they want it.
              </Typography>
            </div>
          }
        />
        <SplitContent
          id="strategy"
          backgroundColor="dark"
          image={
            <StaticImage
              src="../../images/illustrations/node-blue.png"
              alt="blue nodes illustration"
              placeholder="blurred"
            />
          }
          mobileImage={
            <img
              src={strategy}
              alt="blue nodes illustration"
              placeholder="blurred"
              width={250}
              height={250}
            />
          }
          mainContent={
            <div
              data-sal="slide-right"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Typography variant="h2">
                Strategy<span className="blue-punctuation-large">.</span>
              </Typography>

              <Typography style={{ marginBottom: '26px' }}>
                Let’s break this down: our social media experts develop a
                strategy tailored to your brand’s goals and objectives based on
                research and analysis, avoiding being trendy just to be trendy.
                Our team creates a data-backed, curated social content calendar
                to distribute organic media to encourage community conversation.
                While developing your social strategy, we consider:
              </Typography>
              <List>
                <ListItem icon={<Check />}>
                  marketing content and campaign development.
                </ListItem>
                <ListItem icon={<Check />}>monitoring and reporting.</ListItem>
                <ListItem icon={<Check />}>
                  engagement, including special events and influencers.
                </ListItem>
              </List>
              <Typography>
                This is all done based on consumer conversations, behaviors, and
                trends to maximize your online efforts without getting canceled.
              </Typography>
            </div>
          }
        />
        <SplitContent
          backgroundColor="light"
          id="engagement"
          image={
            <StaticImage
              src="../../images/illustrations/engagement-blue.png"
              alt="engagement illustration"
              placeholder="blurred"
            />
          }
          mobileImage={
            <img
              src={engagement}
              alt="engagement illustration"
              placeholder="blurred"
              width={250}
              height={250}
            />
          }
          mainContent={
            <div
              data-sal="slide-left"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Typography variant="h2">
                Engagement<span className="blue-punctuation-large">.</span>
              </Typography>

              <Typography style={{ marginBottom: '26px' }}>
                Our Social Engagement team connects with consumers across all
                stages of the journey to foster awareness and advocacy. Through
                our tailored strategy, we cultivate conversion by creating
                unique brand-consumer connections and increasing your customer
                satisfaction. Easter egg: this helps drive brand retention and
                loyalty so customers stay with you through thick and thin.
              </Typography>
              <Typography>
                In addition, we monitor and measure our social media performance
                to ensure that we engage with our brands’ customers on the right
                platform at the right time with the right message.
              </Typography>
            </div>
          }
        />

        <SplitContent
          id="strategy"
          backgroundColor="dark"
          image={
            <img
              src={ecommerce}
              alt="ecommerce illustration"
              placeholder="blurred"
            />
          }
          mobileImage={
            <img
              src={ecommerce}
              alt="ecommerce illustration"
              placeholder="blurred"
              width={250}
              height={250}
            />
          }
          mainContent={
            <div
              data-sal="slide-right"
              data-sal-delay="100"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Typography variant="h2">
                Commerce<span className="blue-punctuation-large">.</span>
              </Typography>

              <Typography>
                In 2021, $37 billion in goods and services were purchased
                through social commerce channels. That number is expected to
                grow to more than $2 trillion (that’s Trillion, with a T, no
                cap) by 2025. If you’re not already leveraging social for
                product and service sales, you’re falling behind.
              </Typography>
              <Typography style={{ marginBottom: '26px' }}>
                With our Social Commerce offering, you can make sure your
                business is up to speed when it comes to this no longer untapped
                marketplace. Our team locates potential customers on social
                media platforms, suggests products and services, and closes the
                sale.
              </Typography>
            </div>
          }
        />

        <VariableContent backgroundColor="primary" alignMainContent="center">
          <div
            className="narrow medium center"
            data-sal="slide-up"
            data-sal-delay="600"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Typography variant="h2" className="center-desktop">
              Don’t Forget to Smash That Contact Button
              <span className="white-punctuation-large">.</span>
            </Typography>
            <Typography
              className="center-desktop narrow center"
              style={{ marginTop: '0' }}
            >
              SocialX is built to build your brand and online presence with our
              three pillars of social success. Connect with us today so we can
              start your viral journey to social dominance.
            </Typography>
            <LinkButton
              to="/contact"
              color="dark"
              className="center-desktop"
              style={{ margin: '0 auto' }}
            >
              Connect With Us
            </LinkButton>
          </div>
        </VariableContent>
      </>
    ),
    disableBreadcrumbs: true,
  }

  return <Layout {...layoutProps} />
}
export default Home
